import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';

export type OnboadrButtonColor =
  | 'success'
  | 'warning'
  | 'danger'
  | 'default'
  | 'simple'
  | 'stroked'
  | 'transparent'
  | 'blue-default'
  | 'blue-outline';

@Component({
  selector: 'onboardr-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatRippleModule, MatTooltipModule],
  templateUrl: './onboardr-button.component.html',
  styleUrl: './onboardr-button.component.scss',
})
export class OnboardrButtonComponent {
  @Input({ required: true }) buttonType: 'submit' | 'button' | 'reset' = 'button';
  @Input() buttonColor: OnboadrButtonColor = 'default';
  @Input() buttonSize: 'mini' | 'small' | 'normal' | 'big' = 'normal';
  @Input() buttonTitle: string = '';
  @Input() customClasses: string;
  @Input() customContentClasses: string;
  @Input() isDisabled: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() tooltipText: string = null;
  @Input() tooltipPosition: TooltipPosition = 'above';

  @Output() onClick = new EventEmitter<boolean>();

  onClickHandler(): void {
    this.onClick.emit(true);
  }

  get buttonClasses() {
    const baseClass = `onboardr-button`;
    const buttonColorClass = `${baseClass}--${this.buttonColor}`;
    const buttonSizeClass = `${baseClass}--${this.buttonSize}`;
    const selectedClass = `${baseClass}--${this.buttonColor}--selected`;
    const disabledClass = this.isDisabled ? `${baseClass}--disabled` : '';

    return {
      [this.elementClass]: this.elementClass,
      [buttonColorClass]: buttonColorClass,
      [buttonSizeClass]: buttonSizeClass,
      [disabledClass]: disabledClass,
      [selectedClass]: this.isSelected,
    };
  }

  get buttonContentClasses() {
    const baseClass = 'onboardr-button-content';
    return {
      [baseClass]: baseClass,
      [this.customContentClasses]: this.customContentClasses,
    };
  }

  @HostBinding('class') get elementClass() {
    return this.customClasses;
  }
}
