<button
  class="onboardr-button"
  [title]="buttonTitle"
  matRipple
  [type]="buttonType"
  (click)="onClickHandler()"
  [disabled]="isDisabled"
  [ngClass]="buttonClasses"
  [matTooltip]="tooltipText"
  [matTooltipPosition]="tooltipPosition"
  >
  <div [class]="buttonContentClasses">
    <ng-content></ng-content>
  </div>
</button>
